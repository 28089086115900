import { BaseResponse } from "../@types";
export interface LoginRequest {
  email: string;
  password: string;
}
export interface LoginResponse extends BaseResponse {
  token: string;
  user: UserType;
}
export interface UserResponse extends BaseResponse {
  data: UserType;
}

export enum UserRoles {
  admin = "admin",
  user = "user",
  vendor = "vendor",
}
export enum UserStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  SUSPENDED = "SUSPENDED",
  SANDBOX = "SANDBOX",
}
export type UserType = {
  status: boolean;
  _id: string;
  email: string;
  name: string;
  role: string;
  verificationCode: string;
  __v?: number;
  ABN?: number;
  contactPerson?: string;
  postCode?: string;
  tradingName?: string;
  address?: string;
  city?: string;
  updatedAt?: string;
  avatar?: Avatar;
  tempPassword?: string;
  password?: string;
};

export type Avatar = {
  _id: string;
  file: UserFile;
  title?: string;
};

export type UserFile = {
  _id: string;
  originalname: string;
  filename: string;
  size: number;
  thumbnailPath: string;
  thumbnailLink: string;
  link: string;
  uploadBy: string;
  mimeType: string;
  format: string;
  createdAt: string;
  updatedAt: string;
  encoding: string;
  destination: string;
  path: string;
  __v: number;
};

export const USER_TOKEN_LOCALSTORAGE = "userTokenKeys";
export function responseToForm(form: any) {
  return {
    ...form,
  };
}
