import axios, { AxiosRequestConfig } from "axios";
import { LoginResponse, USER_TOKEN_LOCALSTORAGE } from "@/api/types/user";
import notification from "antd/lib/notification";
import { getPlatform } from "../config/index";

axios.interceptors.request.use(async (req: AxiosRequestConfig) => {
  try {
    const localStorageToken = localStorage.getItem(USER_TOKEN_LOCALSTORAGE);
    if (localStorageToken) {
      const token: LoginResponse = JSON.parse(localStorageToken);
      if (req.headers) {
        req.headers["x-access-token"] = token.token;
      }
    }
    req.baseURL = getPlatform();
    return req;
  } catch (error: any) {
    if (!error.isRefreshTokenExist) {
      window.location.href = "/";
      return;
    }
  }
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    //check if token expired
    if (error.response.status === 401) {
      localStorage.removeItem(USER_TOKEN_LOCALSTORAGE);
      window.location.href = "/login";
    }
    //show notification on error
    if (error?.response?.data)
      notification.error({
        message: error?.response?.data?.error,
        description: error?.response?.data?.detail,
      });
    throw error;
  }
);
