import styled from "styled-components";

export const StyledSearchForm = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledAvatarForm = styled.div`
  display: inline-flex;
  Image {
    display: inline;
  }
  display: flex;
  justify-content: space-around;
  padding-bottom: 1em;
  align-items: center;

  .ant-card-body {
    padding: 12px;
    display: flex;
    height: 121px;
    width: 121px;
    text-align: center;
    align-items: center;
  }
`;

export const StyledUserFile = styled.div`
  img {
    border-radius: 100%;
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }
`;
export const StyledUserAddFile = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export const StyledUserUploadModal = styled.div`
  display: flex;
  justify-content: space-around;
`;
export const StyledUserUploadTabs = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 8px;
`;
export const StyledUserFileExpandable = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledPostTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const StyledTextFieldsForm = styled.div`
  display: flex;
  justify-content:  space-around;
  align-items: center;
`;