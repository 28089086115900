import React from "react";
import Menu from "antd/es/menu";
import {
  UserOutlined,
  HomeOutlined,
  GlobalOutlined,
  UnorderedListOutlined,
  ApartmentOutlined,
  SolutionOutlined,
  SwitcherOutlined,
  InteractionOutlined,
  ShoppingOutlined,
  CommentOutlined,
  FileSyncOutlined,
  VideoCameraOutlined,
  StarOutlined,
  BellOutlined,
  InfoCircleOutlined,
  CarryOutOutlined,
  CrownOutlined,
  EnvironmentOutlined,
  ProfileOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd/lib/menu";
import type { SelectInfo } from "rc-menu/lib/interface";
import {
  StyledLogo as Logo,
  StyledAcademyLogo as AcademyLogo,
  StyledLogoHolder as LogoHolder,
  StyledSider as Sider,
  StyledMenu as MenuStyle,
} from "@/components/styles/sider";
import { useLocation, useNavigate } from "react-router-dom";

const items: MenuProps["items"] = [
  {
    label: "Home",
    key: "/",
    icon: <HomeOutlined />,
  },
  {
    label: "Users Management",
    key: "/users",
    icon: <UserOutlined />,
  },
  {
    label: "Countries",
    key: "/countries",
    icon: <GlobalOutlined />,
  },
  {
    label: "Listings",
    key: "/listings",
    icon: <UnorderedListOutlined />,
  },
  {
    label: "Categories",
    key: "/categories",
    icon: <ApartmentOutlined />,
  },
  {
    label: "Posts Management",
    key: "/posts",
    icon: <SolutionOutlined />,
  },
  {
    label: "Vouchers",
    key: "/vouchers",
    icon: <SwitcherOutlined />,
  },
  {
    label: "Deals",
    key: "/deals",
    icon: <InteractionOutlined />,
  },
  {
    label: "Orders",
    key: "/orders",
    icon: <ShoppingOutlined />,
  },
  {
    label: "FAQ",
    key: "/faq",
    icon: <CommentOutlined />,
  },
  {
    label: "Ticket",
    key: "/ticket",
    icon: <FileSyncOutlined />,
  },
  {
    label: "Video",
    key: "/video",
    icon: <VideoCameraOutlined />,
  },
  {
    label: "Comment",
    key: "/comment",
    icon: <StarOutlined />,
  },
  {
    label: "About Us",
    key: "/aboutUs",
    icon: <InfoCircleOutlined />,
  },
  {
    label: "News",
    key: "/news",
    icon: <ProfileOutlined />,
  },
  {
    label: "Featured",
    key: "/featured",
    icon: <CrownOutlined />,
  },
  {
    label: "Australian Made",
    key: "/australianMade",
    icon: <EnvironmentOutlined />,
  },
  {
    label: "City",
    key: "/city",
    icon: <ApartmentOutlined />,
  },
  {
    label: "Membership Features",
    key: "/membershipFeatures",
    icon: <UserSwitchOutlined />,
  },
  {
    label: "Membership Type",
    key: "/membershipType",
    icon: <UserSwitchOutlined />,
  },
  {
    label: "Subscription",
    key: "/subscription",
    icon: <BellOutlined />,
  },
  {
    label: "Reservation",
    key: "/reservation",
    icon: <CarryOutOutlined />,
  },
  {
    label: "Quotes",
    key: "/quotes",
    icon: <ProfileOutlined />,
  },
];

export const Sidebar = () => {
  // const { platform } = useContext(UserContext);

  const history = useNavigate();
  const location = useLocation();

  const [collapsed, setCollapsed] = React.useState(false);

  const onChangeSidebar = (menu: SelectInfo) => {
    history(menu.key);
  };

  const autoCollaps = (value: boolean) => {
    setCollapsed(value);
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => autoCollaps(value)}
      theme="light"
      width={250}
    >
      <MenuStyle>
        <LogoHolder>
          {localStorage.getItem("platform") === "forlocal" ? (
            <Logo />
          ) : (
            <AcademyLogo />
          )}
        </LogoHolder>
        <Menu
          defaultSelectedKeys={[location.pathname]}
          mode="inline"
          items={items}
          onSelect={onChangeSidebar}
          className="bg-transparent"
        />
      </MenuStyle>
    </Sider>
  );
};

export default Sidebar;
