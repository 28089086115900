import {
    StyledHeader
} from "@/components/styles/header";
import { UserOutlined } from '@ant-design/icons';
import React from "react";
import {UserContext} from "@/context/userContext";
import Button from 'antd/lib/button'

export const Header = () => {
    const {currentUser, handleLogout} = React.useContext(UserContext)

    return <StyledHeader>
        <div className='flex items-center'>
            <UserOutlined className='mr-2' />
            Welcome {currentUser?.name},
            <Button className='ml-2' type='default' size='small' onClick={handleLogout}>Logout</Button>
        </div>

    </StyledHeader>
}

export default Header;