import { LocationType, MainPhotoType } from "@/api/types/assets";
import { CategoryType } from "@/api/types/category";
import { UserType } from "@/api/types/user";
import moment from "moment";

export enum SocialEnum {
  INSTAGRAM = "instagram",
  FACEBOOK = "facebook",
  TWITTER = "twitter",
  WHATSAPP = "whatsapp",
}

export type StepsType = {
  name: string;
  bindWith?: string[];
};

export enum ListingSectionsEnum {
  mainPhoto = "mainPhoto",
  slogan = "slogan",
  areas = "areas",
  services = "services",
  email = "email",
  links = "links",
  phone = "phone",
  performance = "performance",
  postalCode = "postalCode",
  hours = "hours",
  logo = "logo",
  title = "title",
  address = "address",
  location = "location",
  aboutUs = "aboutUs",
  website = "website",
}

export enum StepsEnum {
  LAYOUT = "layout",
  BANNER = "banner",
  SERVICES = "services",
  PERFORMANCE = "performance",
  WORKING_HOURS = "workingHours",
  ABOUT_US = "aboutUs",
  CONTACT = "contact",
  ADDRESS = "address",
  TAGS = "tags",
}

export const steps: StepsType[] = [
  {
    name: StepsEnum.LAYOUT,
  },
  {
    name: StepsEnum.BANNER,
  },
  {
    name: StepsEnum.SERVICES,
    bindWith: [ListingSectionsEnum.services],
  },
  /*
    {
        name:  StepsEnum.PERFORMANCE,
        bindWith: [ListingSectionsEnum.performance]
    },
*/
  {
    name: StepsEnum.ABOUT_US,
    bindWith: [ListingSectionsEnum.aboutUs],
  },
  {
    name: StepsEnum.WORKING_HOURS,
    bindWith: [ListingSectionsEnum.hours],
  },
  {
    name: StepsEnum.CONTACT,
    bindWith: [
      ListingSectionsEnum.email,
      ListingSectionsEnum.phone,
      ListingSectionsEnum.links,
    ],
  },
  {
    name: StepsEnum.ADDRESS,
    bindWith: [ListingSectionsEnum.address],
  },
  {
    name: StepsEnum.TAGS,
  },
];

export interface ListingRequest extends Listing {
  category: string[];
}

export interface Listing {
  status?: ListingState;
  category: CategoryType[] | string[];
  tags: string[];
  paymentOptions: [];
  areas: number[];
  likes: [];
  reviews: [];
  viewCount?: 0;
  featured: boolean;
  sections: string[] | undefined;
  _id?: string;
  mainPhoto: MainPhotoType | undefined;
  logo: MainPhotoType | undefined;
  template: number | string;
  services: ServicesType[];
  title: string;
  slogan: string;
  location: LocationType | undefined;
  address: string;
  phone: PhoneType[];
  email: string;
  website: string;
  postalCode: string;
  workHours: WorkingHoursType[];
  aboutUsText: string | undefined;
  aboutUsImage: { file: FileType | undefined } | undefined;
  owner?: UserType;
  otherLinks: OtherLinksType[];
  photoAlbum: [];
  createdAt?: string;
  updatedAt?: string;
  city: string;
  country: string;
  focusKeypharse?: string;
  metaDescription: string;
}

export type ListingState = {
  state: ListingStateEnum;
};
export enum ListingStateEnum {
  IN_PROGRESS = "inProgress",
  SEND_TO_VERIFY = "sendToVerify",
}
export type ServicesType = {
  _id?: string;
  name: string;
  description?: string;
  image?: { file: FileType | undefined } | undefined;
};
export type FileType = {
  _id: string;
  originalname: string;
  encoding: string;
  destination: string;
  filename: string;
  path: string;
  size: number;
  duration: number;
  thumbnailPath: string;
  thumbnailLink: string;
  link: string;
  uploadBy: string;
  mimeType: string;
  format: string;
  createdAt: string;
  updatedAt: string;
};

export type PhoneType = {
  _id?: string;
  type: ListingPhoneEnum;
  number: string;
};
export enum ListingPhoneEnum {
  HOME = "Home",
  MOBILE = "Mobile",
  OFFICE = "Office",
  FACTORY = "Factory",
  FAX = "Fax",
}
export type WorkingHoursType = {
  _id?: string;
  day: "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat" | "Sun";
  startTime?: moment.Moment | undefined;
  endTime?: moment.Moment | undefined;
  open?: boolean;
};

export type OtherLinksType = {
  _id?: string;
  link: string;
  label?: string;
};

export type TemplatesDataType = {
  label: string;
  value: string | number;
  items: string[];
};
export const templatesData: TemplatesDataType[] = [
  {
    label: "Default Template",
    items: [
      "mainPhoto",
      "logo",
      "title",
      "address",
      "postalCode",
      "location",
      "email",
      "phone",
      "slogan",
    ],
    value: 1,
  },
  {
    label: "First Template",
    items: [
      "mainPhoto",
      "logo",
      "title",
      "address",
      "areas",
      "postalCode",
      "location",
      "email",
      "phone",
      "services",
      //"performance",
      "website",
      "slogan",
      "aboutUs",
      "links",
      "hours",
    ],
    value: 8,
  },
  {
    label: "FreeTemplate",
    items: [
      "mainPhoto",
      "logo",
      "title",
      "address",
      "areas",
      "postalCode",
      "location",
      "email",
      "phone",
      "services",
      //"performance",
      "website",
      "slogan",
      "aboutUs",
      "links",
      "hours",
    ],
    value: 2,
  },
  {
    label: "template 1",
    items: [
      "mainPhoto",
      "logo",
      "title",
      "address",
      "areas",
      "postalCode",
      "location",
      "email",
      "phone",
      "services",
      //"performance",
      "website",
      "slogan",
      "aboutUs",
      "links",
      "hours",
    ],
    value: 3,
  },
  {
    label: "template 2",
    items: [
      "mainPhoto",
      "logo",
      "title",
      "address",
      "areas",
      "postalCode",
      "location",
      "email",
      "phone",
      "services",
      //"performance",
      "website",
      "slogan",
      "aboutUs",
      "links",
      "hours",
    ],
    value: 4,
  },
  {
    label: "Full Template",
    items: [
      "mainPhoto",
      "logo",
      "title",
      "address",
      "areas",
      "postalCode",
      "location",
      "email",
      "phone",
      "services",
      //"performance",
      "website",
      "slogan",
      "aboutUs",
      "links",
      "hours",
    ],
    value: 5,
  },
  {
    label: "Music Bands",
    items: [
      "mainPhoto",
      "logo",
      "title",
      "address",
      "areas",
      "postalCode",
      "location",
      "email",
      "phone",
      "services",
      //"performance",
      "website",
      "slogan",
      "aboutUs",
      "links",
      "hours",
    ],
    value: 6,
  },
  {
    label: "Restaurants",
    items: [
      "mainPhoto",
      "logo",
      "title",
      "address",
      "areas",
      "postalCode",
      "location",
      "email",
      "phone",
      "services",
      //"performance",
      "website",
      "slogan",
      "aboutUs",
      "links",
      "hours",
    ],
    value: 7,
  },
];
const startTime = moment().hours(9).minutes(0).second(0);
const endTime = moment().hours(17).minutes(0).second(0);

export const ListingInit: ListingRequest = {
  aboutUsImage: { file: undefined },
  aboutUsText: "",
  address: "",
  areas: [],
  category: [],
  city: "",
  country: "60d9ab913f15d6fc12190cbd", //default country on Australia
  email: "",
  featured: false,
  likes: [],
  location: undefined,
  logo: undefined,
  mainPhoto: undefined,
  metaDescription: "",
  otherLinks: [
    { label: SocialEnum.INSTAGRAM, link: "" },
    { label: SocialEnum.FACEBOOK, link: "" },
  ],
  owner: undefined,
  paymentOptions: [],
  phone: [],
  photoAlbum: [],
  postalCode: "",
  reviews: [],
  sections: [],
  services: [
    {
      name: "",
      description: "",
    },
  ],
  slogan: "",
  tags: ["Tag #1", "Tag #2"],
  template: templatesData[0].value as number,
  title: "",
  website: "",
  workHours: [
    { day: "Mon", open: true, startTime, endTime },
    { day: "Tue", open: true, startTime, endTime },
    { day: "Wed", open: true, startTime, endTime },
    { day: "Thu", open: true, startTime, endTime },
    { day: "Fri", open: true, startTime, endTime },
    { day: "Sat", open: false, startTime, endTime },
    { day: "Sun", open: false, startTime, endTime },
  ],
};

export function formToRequest(form: any) {
  return {
    ...form,
  };
}

export function responseToForm(form: any) {
  return {
    ...form,
    // sections: form.sections?.reduce((obj:any, item:string) => {
    //     obj[item] = true;
    //     return obj
    // }, {}),
    // category: [...form?.category?.map((category: any): string => category._id)],
    // areas: [...form.areas],
    // phone: form?.phone?.reduce((obj:any, item: any) => {
    //     obj[item.type.toLowerCase()] = item.number
    //     return obj
    // },{}),
    // tags: [...form?.tags],
    // workHours: ListingInit.workHours.map((item) => {
    //   const currentDay = _find(form.workHours, { day: item.day });
    //   if (currentDay) return { ...currentDay, open: true };
    //   return { ...item, open: false };
    // }),
  };
}
