import styled from 'styled-components'
import tw from 'twin.macro';

export const Title = styled.h1`
  ${tw`text-[#4C466F] text-xl mb-8`}
`;

export const HeaderTitle = styled.h1`
  ${tw`text-[#4C466F] text-xl mb-8 text-center text-2xl`}
`;
