export enum RoutesNames {
  INDEX_PAGE = "/",
  CHOOSE_PLATFORM_PAGE = "/choosePlatform",
  LOGIN_PAGE = "/login",
  USERS_PAGE = "/users",
  USER_UPDATE_PAGE = "/users/userCreateUpdatePage/:id",
  USER_FILE_PAGE = "/users/UserFiles",
  USER_ORDERS_PAGE = "/users/orders",
  COUNTRIES_PAGE = "/Countries",

  LISTINGS_PAGE = "/listings",
  LISTINGS_UPDATE_PAGE = "/listings/listingUpdatePage/:id",
  LISTINGS_SERVICES_PAGE = "/listings/services",
  LISTINGS_SERVICE_EDIT_PAGE = "/listings/services/serviceEdit",
  LISTINGS_STAFF_PAGE = "/listings/staff",
  LISTINGS_STAFF_EDIT_PAGE = "/listings/staff/staffEdit",
  LISTINGS_MENU_PAGE = "/listings/menu/:id",
  LISTINGS_ORDERS_PAGE = "/listings/orders",

  CATEGORIES_PAGE = "/categories",
  CATEGORY_UPDATE_PAGE = "/categories/categoryUpdatePage/:id",

  POST_MANAGEMENT_PAGE = "/posts",
  POST_UPDATE_PAGE = "/posts/postCreateUpdatePage/:id",
  DEALS_PAGE = "/deals",
  DEALS_UPDATE_PAGE = "/deals/dealsUpdatePage/:id",
  VOUCHER_PAGE = "/vouchers",
  VOUCHERS_UPDATE_PAGE = "/vouchers/vouchersUpdatePage/:id",
  ORDER_PAGE = "/orders",
  ORDER_VIEW_PAGE = "/orders/orderViewPage/:id",

  FAQ_PAGE = "/faq",
  FAQ_UPDATE_PAGE = "/faq/faqCreateUpdatePage/:id",

  TICKET_PAGE = "/ticket",
  VIDEO_PAGE = "/video",
  VIDEO_VIEW_PAGE = "/video/videoUpdatePage/:id",
  RATE_PAGE = "/comment",
  ABOUT_US_PAGE = "/aboutUs",
  ABOUT_US_UPDATE_PAGE = "/aboutUs/aboutUsCreateUpdatePage/:id",

  NEWS_PAGE = "/news",
  NEWS_UPDATE_PAGE = "/news/newsUpdatePage/:id",

  FEATURED_PAGE = "/featured",
  FEATURED_UPDATE_PAGE = "/featured/featureCreateUpdatePage/:id",

  AUSTRALIAN_MADE_PAGE = "/australianMade",
  AUSTRALIAN_MADE_UPDATE_PAGE = "/australianMade/australianMadeUpdatePage/:id",
  CITY_PAGE = "/city",
  CITY_UPDATE_PAGE = "/city/cityCreateUpdatePage/:id",
  MEMBERSHIP_FEATURES_PAGE = "/membershipFeatures",
  MEMBERSHIP_FEATURES_UPDATE_PAGE = "/membershipFeatures/membershipFeaturesCreateUpdatePage/:id",
  MEMBERSHIP_TYPE_PAGE = "/membershipType",
  SUBSCRIPTION_PAGE = "/subscription",
  MEMBERSHIP_TYPE_UPDATE_PAGE = "/membershipType/membershipTypeCreateUpdatePage/:id",
  RESERVATION_PAGE = "/reservation",
  VENDOR_TRANSACTION_PAGE = "/vendorTransactions",
  QUOTES_PAGE = "/quotes",
  NotFound = "*",
}

type RouteData = {
  route: string;
  component: any;
  key: string;
  param?: string | string[];
};

export interface Routes {
  choosePlatformPage: RouteData;
  loginPage: RouteData;
  indexPage: RouteData;
  usersPage: RouteData;
  userUpdatePage: RouteData;
  userFilePage: RouteData;
  userOrdersPage: RouteData;
  countriesPage: RouteData;
  listingsPage: RouteData;
  listingsUpdatePage: RouteData;
  listingsServicesPage: RouteData;
  listingsServiceEditPage: RouteData;
  listingsStaffPage: RouteData;
  listingsStaffEditPage: RouteData;
  listingsMenuPage: RouteData;
  listingsOrdersPage: RouteData;
  categoriesPage: RouteData;
  categoryUpdatePage: RouteData;
  postManagementPage: RouteData;
  postUpdatePage: RouteData;
  dealsPage: RouteData;
  dealsUpdatePage: RouteData;
  voucherPage: RouteData;
  vouchersUpdatePage: RouteData;
  orderPage: RouteData;
  orderViewPage: RouteData;
  faqPage: RouteData;
  faqUpdatePage: RouteData;
  ticketPage: RouteData;
  videoPage: RouteData;
  videosUpdatePage: RouteData;
  ratePage: RouteData;
  aboutUsPage: RouteData;
  aboutUsUpdatePage: RouteData;
  newsPage: RouteData;
  newsUpdatePage: RouteData;
  featuredPage: RouteData;
  featuredUpdatePage: RouteData;
  australianMadePage: RouteData;
  australianMadeUpdatePage: RouteData;
  cityPage: RouteData;
  cityUpdatePage: RouteData;
  membershipFeaturesPage: RouteData;
  membershipFeaturesUpdatePage: RouteData;
  membershipTypePage: RouteData;
  membershipTypeUpdatePage: RouteData;
  vendorTransactionsPage: RouteData;
  subscriptionPage: RouteData;
  reservationPage: RouteData;
  quotesPage: RouteData;
  notFound: RouteData;
}
