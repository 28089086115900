import React from 'react';
import 'antd/dist/antd.less';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from '@/routes/Routes';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import '@/api/interceptors';
import {CurrentUserProvider} from '@/context/userContext';
import '@/i18n/i18n';

function App() {
  const queryClient = new QueryClient();
  return (
    <>
      <QueryClientProvider client={queryClient}>
            <Router>
                <CurrentUserProvider>
                    <Routes />
                </CurrentUserProvider>
            </Router>
      </QueryClientProvider>
    </>
  );
}

export default App;
