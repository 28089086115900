let platformSelected;
const platforms = {
  forlocal: "https://forlocal.online/",
  academy: "https://wynforlocal.com.au/",
};

module.exports = {
  setPlatform(platform) {
    platformSelected = platform;
    localStorage.setItem("platform", platform);
  },
  getPlatform() {
    return platforms[platformSelected]
      ? platforms[platformSelected]
      : platforms[localStorage.getItem("platform")];
  },
};
