import { lazy } from "react";
import { Routes, RoutesNames } from "./Routes.types";
import ListingsServices from "@/components/forms/CreateUpdate/listings/listingServices/ListingsServicesPage";
const choosePlatformPage = lazy(() => import("../pages/choosePlatform"));
const IndexPage = lazy(() => import("../pages/index"));
const UsersPage = lazy(() => import("../pages/users"));
const LoginPage = lazy(() => import("../pages/login"));
const CountriesPage = lazy(() => import("../pages/countries"));
const ListingsPage = lazy(() => import("../pages/listings"));

// listing update page
const ListingsUpdatePage = lazy(
  () =>
    import("../components/forms/CreateUpdate/listings/ListingsCreateUpdateForm")
);
// listing edit service page
const ListingsServiceCreateUpdatePage = lazy(
  () =>
    import(
      "../components/forms/CreateUpdate/listings/listingServices/ListingsServiceCreateUpdateForm"
    )
);
// listing edit staff page
const listingsStaffPage = lazy(
  () =>
    import(
      "../components/forms/CreateUpdate/listings/listingStaff/ListingsStaff"
    )
);
const ListingsStaffCreateUpdateForm = lazy(
  () =>
    import(
      "../components/forms/CreateUpdate/listings/listingStaff/ListingsStaffCreateUpdateForm"
    )
);
// user update page
const UserUpdatePage = lazy(
  () => import("../components/forms/CreateUpdate/users/UserCreateUpdateForm")
);
// listing menu page
const listingsMenuPage = lazy(
  () =>
    import(
      "../components/forms/CreateUpdate/listings/listingMenu/ListingMenuPage"
    )
);
const listingsOrdersPage = lazy(
  () =>
    import(
      "../components/forms/CreateUpdate/listings/listingOrders/listingsOrdersPage"
    )
);
const CategoriesPage = lazy(() => import("../pages/categories"));
const CategoryUpdatePage = lazy(
  () =>
    import(
      "../components/forms/CreateUpdate/categories/CategoryCreateUpdateForm"
    )
);

const PostManagementPage = lazy(() => import("../pages/posts"));
const PostUpdatePage = lazy(
  () => import("../components/forms/CreateUpdate/post/PostCreateUpdateForm")
);
const DealsPage = lazy(() => import("../pages/deals"));
const DealsUpdatePage = lazy(
  () => import("../components/forms/CreateUpdate/deal/DealCreateUpdateForm")
);
const VoucherPage = lazy(() => import("../pages/vouchers"));
const VouchersUpdatePage = lazy(
  () =>
    import("../components/forms/CreateUpdate/voucher/VoucherCreateUpdateForm")
);
const OrderPage = lazy(() => import("../pages/orders"));
const FaqPage = lazy(() => import("../pages/faqs"));
const FaqUpdatePage = lazy(
  () => import("../components/forms/CreateUpdate/FAQ/FaqCreateUpdateForm")
);
const TicketPage = lazy(() => import("../pages/tickets"));
const VideoPage = lazy(() => import("../pages/videos"));
const VideosUpdatePage = lazy(
  () => import("../components/forms/CreateUpdate/video/VideoCreateUpdateForm")
);
const RatePage = lazy(() => import("../pages/rates"));
const AboutUsPage = lazy(() => import("../pages/aboutUs"));
const AboutUsUpdatePage = lazy(
  () =>
    import("../components/forms/CreateUpdate/aboutUs/AboutUsCreateUpdateForm")
);
const NewsPage = lazy(() => import("../pages/news"));
const NewsUpdatePage = lazy(
  () => import("../components/forms/CreateUpdate/news/NewsCreateUpdateForm")
);
const FeaturedPage = lazy(() => import("../pages/features"));
const FeaturedUpdatePage = lazy(
  () =>
    import("../components/forms/CreateUpdate/features/FeaturesCreateUpdateForm")
);
const AustralianMadePage = lazy(() => import("../pages/australiansMade"));
const AustralianMadeUpdatePage = lazy(
  () =>
    import(
      "../components/forms/CreateUpdate/australianMade/AustralianMadeCreateUpdateForm"
    )
);
const ReservationPage = lazy(() => import("../pages/reservations"));
const VendorTransactionsPage = lazy(
  () => import("../pages/vendorTransactions")
);
const UserFilesPage = lazy(() => import("../pages/usersFile"));
const UserOrdersPage = lazy(() => import("../pages/userOrders"));
const CityPage = lazy(() => import("../pages/cities"));
const CityUpdatePage = lazy(
  () => import("../components/forms/CreateUpdate/Cities/CityCreateUpdateForm")
);
const MembershipFeaturesPage = lazy(
  () => import("../pages/membershipFeatures")
);
const MembershipFeaturesUpdatePage = lazy(
  () =>
    import(
      "../components/forms/CreateUpdate/membershipFeature/MembershipFeatureCreateUpdateForm"
    )
);
const MembershipTypePage = lazy(() => import("../pages/membershipTypes"));
const MembershipTypeUpdatePage = lazy(
  () =>
    import(
      "../components/forms/CreateUpdate/membershipType/MembershipTypeCreateUpdateForm"
    )
);
const SubscriptionPage = lazy(() => import("../pages/subscription"));
const OrderViewPage = lazy(
  () => import("../components/forms/CreateUpdate/order/OrderCreateUpdateForm")
);
const QuotesPage = lazy(() => import("../pages/quotes"));

const Page404 = lazy(() => import("../pages/404Pages"));

const routes: Routes = {
  choosePlatformPage: {
    route: RoutesNames.CHOOSE_PLATFORM_PAGE,
    component: choosePlatformPage,
    key: "choosePlatform",
  },
  loginPage: {
    route: RoutesNames.LOGIN_PAGE,
    component: LoginPage,
    key: "login",
  },
  indexPage: {
    route: RoutesNames.INDEX_PAGE,
    component: IndexPage,
    key: "home",
  },
  usersPage: {
    route: RoutesNames.USERS_PAGE,
    component: UsersPage,
    key: "users",
  },
  userUpdatePage: {
    route: RoutesNames.USER_UPDATE_PAGE,
    component: UserUpdatePage,
    key: "userUpdatePage",
  },
  userFilePage: {
    route: RoutesNames.USER_FILE_PAGE,
    component: UserFilesPage,
    key: "userFilesPage",
  },
  userOrdersPage: {
    route: RoutesNames.USER_ORDERS_PAGE,
    component: UserOrdersPage,
    key: "userOrdersPage",
  },
  countriesPage: {
    route: RoutesNames.COUNTRIES_PAGE,
    component: CountriesPage,
    key: "countries",
  },
  listingsPage: {
    route: RoutesNames.LISTINGS_PAGE,
    component: ListingsPage,
    key: "listings",
  },
  // listing update page
  listingsUpdatePage: {
    route: RoutesNames.LISTINGS_UPDATE_PAGE,
    component: ListingsUpdatePage,
    key: "listingsViewPage",
  },

  listingsServicesPage: {
    route: RoutesNames.LISTINGS_SERVICES_PAGE,
    component: ListingsServices,
    key: "listingsServices",
  },
  listingsServiceEditPage: {
    route: RoutesNames.LISTINGS_SERVICE_EDIT_PAGE,
    component: ListingsServiceCreateUpdatePage,
    key: "listingsServiceEdit",
  },
  listingsStaffPage: {
    route: RoutesNames.LISTINGS_STAFF_PAGE,
    component: listingsStaffPage,
    key: "listingsStaffPage",
  },
  listingsStaffEditPage: {
    route: RoutesNames.LISTINGS_STAFF_EDIT_PAGE,
    component: ListingsStaffCreateUpdateForm,
    key: "listingsStaffEdit",
  },
  // listing menu page
  listingsMenuPage: {
    route: RoutesNames.LISTINGS_MENU_PAGE,
    component: listingsMenuPage,
    key: "listingsMenuPage",
  },
  listingsOrdersPage: {
    route: RoutesNames.LISTINGS_ORDERS_PAGE,
    component: listingsOrdersPage,
    key: "listingsOrdersPage",
  },
  // categories page
  categoriesPage: {
    route: RoutesNames.CATEGORIES_PAGE,
    component: CategoriesPage,
    key: "categories",
  },
  categoryUpdatePage: {
    route: RoutesNames.CATEGORY_UPDATE_PAGE,
    component: CategoryUpdatePage,
    key: "categoryUpdatePage",
  },
  postManagementPage: {
    route: RoutesNames.POST_MANAGEMENT_PAGE,
    component: PostManagementPage,
    key: "postManagementPage",
  },
  postUpdatePage: {
    route: RoutesNames.POST_UPDATE_PAGE,
    component: PostUpdatePage,
    key: "postUpdatePage",
  },
  dealsPage: {
    route: RoutesNames.DEALS_PAGE,
    component: DealsPage,
    key: "dealsPage",
  },
  dealsUpdatePage: {
    route: RoutesNames.DEALS_UPDATE_PAGE,
    component: DealsUpdatePage,
    key: "dealsUpdatePage",
  },
  voucherPage: {
    route: RoutesNames.VOUCHER_PAGE,
    component: VoucherPage,
    key: "voucherPage",
  },
  // voucher update page
  vouchersUpdatePage: {
    route: RoutesNames.VOUCHERS_UPDATE_PAGE,
    component: VouchersUpdatePage,
    key: "vouchersUpdatePage",
  },
  orderPage: {
    route: RoutesNames.ORDER_PAGE,
    component: OrderPage,
    key: "orderPage",
  },
  orderViewPage: {
    route: RoutesNames.ORDER_VIEW_PAGE,
    component: OrderViewPage,
    key: "orderViewPage",
  },
  faqPage: {
    route: RoutesNames.FAQ_PAGE,
    component: FaqPage,
    key: "faqPage",
  },
  faqUpdatePage: {
    route: RoutesNames.FAQ_UPDATE_PAGE,
    component: FaqUpdatePage,
    key: "faqUpdatePage",
  },
  ticketPage: {
    route: RoutesNames.TICKET_PAGE,
    component: TicketPage,
    key: "ticketPage",
  },
  // Video pages
  videoPage: {
    route: RoutesNames.VIDEO_PAGE,
    component: VideoPage,
    key: "videoPage",
  },
  videosUpdatePage: {
    route: RoutesNames.VIDEO_VIEW_PAGE,
    component: VideosUpdatePage,
    key: "videosUpdatePage",
  },
  ratePage: {
    route: RoutesNames.RATE_PAGE,
    component: RatePage,
    key: "ratePage",
  },
  // about Us pages

  aboutUsPage: {
    route: RoutesNames.ABOUT_US_PAGE,
    component: AboutUsPage,
    key: "aboutUsPage",
  },
  aboutUsUpdatePage: {
    route: RoutesNames.ABOUT_US_UPDATE_PAGE,
    component: AboutUsUpdatePage,
    key: "aboutUsUpdatePage",
  },
  newsPage: {
    route: RoutesNames.NEWS_PAGE,
    component: NewsPage,
    key: "newsPage",
  },
  // voucher update page
  newsUpdatePage: {
    route: RoutesNames.NEWS_UPDATE_PAGE,
    component: NewsUpdatePage,
    key: "newsUpdatePage",
  },
  // featured pages
  featuredPage: {
    route: RoutesNames.FEATURED_PAGE,
    component: FeaturedPage,
    key: "featuredPage",
  },
  featuredUpdatePage: {
    route: RoutesNames.FEATURED_UPDATE_PAGE,
    component: FeaturedUpdatePage,
    key: "featuredUpdatePage",
  },
  australianMadePage: {
    route: RoutesNames.AUSTRALIAN_MADE_PAGE,
    component: AustralianMadePage,
    key: "australianMadePage",
  },
  // Australian Made pages
  australianMadeUpdatePage: {
    route: RoutesNames.AUSTRALIAN_MADE_UPDATE_PAGE,
    component: AustralianMadeUpdatePage,
    key: "australianMadeUpdatePage",
  },
  vendorTransactionsPage: {
    route: RoutesNames.VENDOR_TRANSACTION_PAGE,
    component: VendorTransactionsPage,
    key: "vendorTransactionsPage",
  },
  // city pages
  cityPage: {
    route: RoutesNames.CITY_PAGE,
    component: CityPage,
    key: "cityPage",
  },
  cityUpdatePage: {
    route: RoutesNames.CITY_UPDATE_PAGE,
    component: CityUpdatePage,
    key: "cityUpdatePage",
  },
  // membership Features pages
  membershipFeaturesPage: {
    route: RoutesNames.MEMBERSHIP_FEATURES_PAGE,
    component: MembershipFeaturesPage,
    key: "membershipFeaturesPage",
  },
  membershipFeaturesUpdatePage: {
    route: RoutesNames.MEMBERSHIP_FEATURES_UPDATE_PAGE,
    component: MembershipFeaturesUpdatePage,
    key: "membershipFeaturesUpdatePage",
  },
  membershipTypePage: {
    route: RoutesNames.MEMBERSHIP_TYPE_PAGE,
    component: MembershipTypePage,
    key: "membershipTypePage",
  },
  membershipTypeUpdatePage: {
    route: RoutesNames.MEMBERSHIP_TYPE_UPDATE_PAGE,
    component: MembershipTypeUpdatePage,
    key: "membershipTypeUpdatePage",
  },
  subscriptionPage: {
    route: RoutesNames.SUBSCRIPTION_PAGE,
    component: SubscriptionPage,
    key: "subscriptionPage",
  },
  reservationPage: {
    route: RoutesNames.RESERVATION_PAGE,
    component: ReservationPage,
    key: "reservationPage",
  },
  quotesPage: {
    route: RoutesNames.QUOTES_PAGE,
    component: QuotesPage,
    key: "quotesPage",
  },
  notFound: {
    route: RoutesNames.NotFound,
    component: Page404,
    key: "notFound",
  },
};
export default routes;
