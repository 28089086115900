import axios, {Method} from "axios";
import {useMutation, useQuery} from "@tanstack/react-query";

interface GetRequestResponse {
    isLoading: boolean,
    error: any,
    data: any,
    isFetching: boolean,
    isError: boolean,
    refetch: () => void
}

interface PostRequestResponse {
    mutate: any,
    data: any,
    isLoading: boolean,
    isError: boolean,
    isSuccess: boolean,
    error: any,
    mutateAsync:any
}

interface PostRequestWithFullControlArgs {
    onMutate?: (variables: any) => void,
    onError?: (error: any, variables: any, context: any) => void,
    onSuccess?: (data: any, variables: any, context: any) => void,
    onSettled?: (data: any, error: any, variables: any, context: any) => void
}
interface GetRequestWithFullControlArgs {
    onError?: (error: any) => void,
    onSuccess?: (data: any) => void,
}

function fetchData(apiUrl: string) {
    return axios.get(apiUrl).then((res) => res.data)
}


export function GetRequest(apiKey: readonly unknown[], apiUrl: string, args: GetRequestWithFullControlArgs = {}, enabled: boolean = false, queryHash:string|undefined = undefined): GetRequestResponse {
    const { data, error, isFetching, isLoading, isError, refetch } = useQuery(apiKey,
        () => fetchData(apiUrl),
        {
            onSuccess: (data:any) => {
                if (data.data) {
                    data = data.data;
                }
                args.onSuccess &&
                args.onSuccess(data);
            },
            onError: (error: any) => {
                args.onError && args.onError(error);
            },
            retry: 0,
            enabled,
            queryHash,
        }
    )
    return { data, error, isFetching, isLoading, isError, refetch }
}


export function PostRequest(url: string, args: PostRequestWithFullControlArgs, method:Method = 'POST', toBodyToParams?: boolean): PostRequestResponse {
    const { mutate, data, isLoading, isError, isSuccess, error, mutateAsync } = useMutation((body:any) => {
        // const data = new URLSearchParams();
        // use for path params queries like update user and delete user
        if (toBodyToParams && body){
            (Object.keys(body) as (keyof typeof body)[]).forEach(key => {
                url = `${url}/${body[key]}`
            })
        }

        const options = {
            method,
            headers: { 'content-type': 'application/json; charset=utf-8' },
            data: JSON.stringify(body),
            url,
        };

        return axios(options)
    },{
        onMutate: (variables) => {
            args.onMutate && args.onMutate(variables);
        },
        onError: (error: any, variables, context) => {
            args.onError && args.onError(error, variables, context);
        },
        onSuccess: (data, variables, context) => {
            if (data.data) {
                data = data.data;
            }
            args.onSuccess &&
            args.onSuccess(data, variables, context);
        },
        onSettled: (data, error, variables, context) => {
            args.onSettled && args.onSettled(data, error, variables, context);
        },

    })
    return { mutate, data, isLoading, isError, isSuccess, error,mutateAsync }
}