import React from "react";
import Layout from "antd/es/layout";
import Header from "@/components/Layout/Header";
import Sidebar from "@/components/Layout/Sidebar";
import Footer from "@/components/Layout/Footer";
import { UserContext } from "@/context/userContext";
import {
  StyledHolder as Holder,
  StyledMain as Main,
} from "@/components/styles/Layout";

const { Content } = Layout;

const MainLayout = ({ children }: React.PropsWithChildren) => {
  const { authenticationLoading } = React.useContext(UserContext);

  if (authenticationLoading) return <div>Loading...</div>;

  return (
    <Main>
      <Sidebar />
      <Content className="flex flex-col justify-between">
        <Header />
        <Holder>{children}</Holder>
        <Footer />
      </Content>
    </Main>
  );
};

export default MainLayout;
