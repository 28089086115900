import styled from "styled-components";
import tw from "twin.macro";
import Layout from "antd/es/layout";
const { Header } = Layout;

export const StyledHeader = styled(Header)`
  ${tw`flex items-center justify-end text-primary h-[50px] bg-white`}
  background: linear-gradient(0.15deg,#EAF0FF 1.98%,#FFFFFF 98.4%);
  box-shadow: 10px 10px 100px 0 rgb(0 0 0 / 30%);
}`;
