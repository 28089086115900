import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { listingType } from "@/api/types/listing";
import { Button, Card, Image, Space, Spin } from "antd";
import Meta from "antd/lib/card/Meta";
import Layout from "@/components/Layout/Layout";
import { Title } from "@/components/styles/page";
import { StyledUserFile as UserFileStyle } from "@/components/styles/user";
import { StyledUserUploadTabs as TabStyle } from "@/components/styles/user";
import { EditOutlined } from "@ant-design/icons";
import { urls } from "@/api/urls";
import { GetRequest } from "@/api/handleReactQuery";
import { responseToForm } from "../Listing";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { StyledListingsMenu as StyledMenu } from "@/components/styles/listings";

export default function ListingsServices() {
  const { state }: any = useLocation();
  const [listingData, setListingData] = useState<listingType | null>();
  const [listingId, setListingId] = useState<string | undefined>();
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => reset({}), 100);
    if (state?.record.services && listingData?.services?.length === 0) {
      setListingId(state?.record._id);
      setListingData(null);
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBack = () => {
    navigate("../listings");
  };

  // handle form
  const { reset } = useForm<listingType>({
    defaultValues: {},
  });

  // get Initial user
  const { isFetching, refetch } = GetRequest(
    ["GET_SINGLE_USER"],
    `${urls.listings.single}/${state?.record._id}`,
    {
      onSuccess: (data) => {
        if (data?.message) {
          reset({
            ...responseToForm(data?.message),
          });
          setListingData(data?.message);
        } else {
          toast("you should have a listing to create your own users", {
            type: "warning",
          });
        }
      },
      onError: (error: any) => {
        toast.error(error.response.data.message);
      },
    },
    !listingId
  );

  const updateServices = (record?: listingType) => {
    if (record) {
      navigate("./serviceEdit", {
        state: { record: record, listingData: listingData },
      });
    } else {
      navigate(`./serviceEdit`, {
        state: { record: record, listingData: listingData },
      });
    }
  };

  return (
    <Layout>
      <TabStyle>
        <Title>
          {state?.record && (
            <UserFileStyle>
              {`${state?.record?.owner?.name}'s Services `}
              {state?.record?.logo?.file && (
                <Image
                  preview={{
                    src: `https://forlocal.online/uploads/${state?.record.logo.file.link}`,
                  }}
                  src={`https://forlocal.online/uploads/${state?.record.logo.file.thumbnailPath}`}
                />
              )}
            </UserFileStyle>
          )}
        </Title>
        <Space>
          <Button onClick={() => updateServices()} type="primary">
            Add New Service
          </Button>
          <Button onClick={onBack}>Back</Button>
        </Space>
      </TabStyle>
      <Spin spinning={isFetching}>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {listingData?.services?.map((service: any) => {
            return (
              <StyledMenu>
                <Card
                  key={service?._id}
                  className="card"
                  title={`${service?.name}`}
                  style={{ width: 200, margin: "10px" }}
                  actions={[
                    <StyledMenu>
                      <EditOutlined
                        style={{ fontSize: 20 }}
                        key="edit"
                        type="message"
                        onClick={() => updateServices(service)}
                      />
                    </StyledMenu>,
                  ]}
                  cover={
                    service?.image?.file ? (
                      <Image
                        style={{ borderRadius: 0 }}
                        preview={{
                          src: `https://forlocal.online/uploads/${service?.image?.file?.link}`,
                        }}
                        src={`https://forlocal.online/uploads/${service?.image?.file?.thumbnailPath}`}
                        width={200}
                        height={200}
                        key={service?._id}
                      />
                    ) : (
                      <div className="no-image">there is no file</div>
                    )
                  }
                >
                  <Meta
                    style={{ height: "300px" }}
                    title={service.name}
                    description={service?.description}
                  />
                </Card>
              </StyledMenu>
            );
          })}
        </div>
      </Spin>
    </Layout>
  );
}
