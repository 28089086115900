import Layout from "antd/es/layout";
import { useTranslation } from "react-i18next";
const { Footer: AntFooter } = Layout;

export const Footer = () => {
  const { t } = useTranslation();

  return <AntFooter className="pb-3">{t("footer.copyright")}</AntFooter>;
};

export default Footer;
