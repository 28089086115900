import styled from "styled-components";
import tw from "twin.macro";

export const StyledTextFieldsForm = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledSelectFieldsForm = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledListingsTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledListingsImages = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
export const Title = styled.h1`
  ${tw`text-[#4C466F] text-xl mb-30`}
  text-align: center;
`;
export const StyledListingsColumns = styled.div`
  width: 30%;
`;
export const StyledListingsMapColumns = styled.div`
  width: 45%;
  margin-top: -23px;
`;
export const StyledSingleRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 23px;
  span {
    margin-bottom: 24px;
  }
`;

export const StyledListingsWorkHours = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledListingsMenu = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-around;
  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .menu-body {
    height: 220px;
  }
  .no-image {
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
  }
`;
export const StyledVideoForm = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  button {
    margin-left: 40px;
  }
  video {
    border-radius: 0.75rem;
  }
  .ant-card-body {
    padding: 0;
    width: 250px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
