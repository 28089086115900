import React, { Suspense } from "react";
import { Routes as Switch, Route, useLocation } from "react-router-dom";
import routes from "./RoutesConfig";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

//on route change scroll page to top
const usePageViews = () => {
  let location = useLocation();
  React.useEffect(() => {
    if (location.pathname === "/" && location.hash !== "") return;
    window.scrollTo(0, 0);
  }, [location]);
};

// for prevent refetch data in post update
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

const Routes = () => {
  usePageViews();

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route
          path={routes.choosePlatformPage.route}
          element={<routes.choosePlatformPage.component />}
        />
        <Route
          path={routes.indexPage.route}
          element={<routes.indexPage.component />}
        />
        <Route
          path={routes.loginPage.route}
          element={<routes.loginPage.component />}
        />
        <Route
          path={routes.usersPage.route}
          element={<routes.usersPage.component />}
          key={routes.usersPage.key}
        />
        {/* user update page */}
        <Route
          path={routes.userUpdatePage.route}
          element={
            <QueryClientProvider client={queryClient}>
              <routes.userUpdatePage.component />
            </QueryClientProvider>
          }
          key={routes.userUpdatePage.key}
        />
        <Route
          path={routes.userFilePage.route}
          element={<routes.userFilePage.component />}
          key={routes.userFilePage.key}
        />
        <Route
          path={routes.userOrdersPage.route}
          element={<routes.userOrdersPage.component />}
          key={routes.userOrdersPage.key}
        />
        <Route
          path={routes.countriesPage.route}
          element={<routes.countriesPage.component />}
          key={routes.countriesPage.key}
        />
        <Route
          path={routes.listingsPage.route}
          element={<routes.listingsPage.component />}
          key={routes.listingsPage.key}
        />
        {/* listing update page */}
        <Route
          path={routes.listingsUpdatePage.route}
          element={
            <QueryClientProvider client={queryClient}>
              <routes.listingsUpdatePage.component />
            </QueryClientProvider>
          }
          key={routes.listingsUpdatePage.key}
        />
        <Route
          path={routes.listingsServicesPage.route}
          element={
            <QueryClientProvider client={queryClient}>
              <routes.listingsServicesPage.component />
            </QueryClientProvider>
          }
          key={routes.listingsServicesPage.key}
        />
        <Route
          path={routes.listingsServiceEditPage.route}
          element={
            <QueryClientProvider client={queryClient}>
              <routes.listingsServiceEditPage.component />
            </QueryClientProvider>
          }
          key={routes.listingsServiceEditPage.key}
        />
        <Route
          path={routes.listingsStaffPage.route}
          element={
            <QueryClientProvider client={queryClient}>
              <routes.listingsStaffPage.component />
            </QueryClientProvider>
          }
          key={routes.listingsStaffPage.key}
        />
        <Route
          path={routes.listingsStaffEditPage.route}
          element={
            <QueryClientProvider client={queryClient}>
              <routes.listingsStaffEditPage.component />
            </QueryClientProvider>
          }
          key={routes.listingsStaffEditPage.key}
        />
        {/* listing menu page */}
        <Route
          path={routes.listingsMenuPage.route}
          element={
            <QueryClientProvider client={queryClient}>
              <routes.listingsMenuPage.component />
            </QueryClientProvider>
          }
          key={routes.listingsMenuPage.key}
        />
        <Route
          path={routes.listingsOrdersPage.route}
          element={<routes.listingsOrdersPage.component />}
          key={routes.listingsOrdersPage.key}
        />

        {/* category update page */}
        <Route
          path={routes.categoriesPage.route}
          element={<routes.categoriesPage.component />}
          key={routes.categoriesPage.key}
        />
        <Route
          path={routes.categoryUpdatePage.route}
          element={
            <QueryClientProvider client={queryClient}>
              <routes.categoryUpdatePage.component />
            </QueryClientProvider>
          }
          key={routes.categoryUpdatePage.key}
        />
        <Route
          path={routes.postManagementPage.route}
          element={<routes.postManagementPage.component />}
          key={routes.postManagementPage.key}
        />
        <Route
          path={routes.postUpdatePage.route}
          element={
            <QueryClientProvider client={queryClient}>
              <routes.postUpdatePage.component />
            </QueryClientProvider>
          }
          key={routes.postUpdatePage.key}
        />
        <Route
          path={routes.dealsPage.route}
          element={<routes.dealsPage.component />}
          key={routes.dealsPage.key}
        />
        {/* deals update page */}
        <Route
          path={routes.dealsUpdatePage.route}
          element={
            <QueryClientProvider client={queryClient}>
              <routes.dealsUpdatePage.component />
            </QueryClientProvider>
          }
          key={routes.dealsUpdatePage.key}
        />
        <Route
          path={routes.voucherPage.route}
          element={<routes.voucherPage.component />}
          key={routes.voucherPage.key}
        />
        {/* voucher update page */}
        <Route
          path={routes.vouchersUpdatePage.route}
          element={
            <QueryClientProvider client={queryClient}>
              <routes.vouchersUpdatePage.component />
            </QueryClientProvider>
          }
          key={routes.vouchersUpdatePage.key}
        />
        <Route
          path={routes.orderPage.route}
          element={<routes.orderPage.component />}
          key={routes.orderPage.key}
        />
        <Route
          path={routes.orderViewPage.route}
          element={
            <QueryClientProvider client={queryClient}>
              <routes.orderViewPage.component />
            </QueryClientProvider>
          }
          key={routes.orderViewPage.key}
        />
        <Route
          path={routes.faqPage.route}
          element={<routes.faqPage.component />}
          key={routes.faqPage.key}
        />
        {/* faq update page */}
        <Route
          path={routes.faqUpdatePage.route}
          element={
            <QueryClientProvider client={queryClient}>
              <routes.faqUpdatePage.component />
            </QueryClientProvider>
          }
          key={routes.faqUpdatePage.key}
        />

        <Route
          path={routes.ticketPage.route}
          element={<routes.ticketPage.component />}
          key={routes.ticketPage.key}
        />
        <Route
          path={routes.videoPage.route}
          element={<routes.videoPage.component />}
          key={routes.videoPage.key}
        />
        <Route
          path={routes.videosUpdatePage.route}
          element={
            <QueryClientProvider client={queryClient}>
              <routes.videosUpdatePage.component />
            </QueryClientProvider>
          }
          key={routes.videosUpdatePage.key}
        />
        <Route
          path={routes.ratePage.route}
          element={<routes.ratePage.component />}
          key={routes.ratePage.key}
        />
        {/* about us pages */}
        <Route
          path={routes.aboutUsPage.route}
          element={<routes.aboutUsPage.component />}
          key={routes.aboutUsPage.key}
        />
        <Route
          path={routes.aboutUsUpdatePage.route}
          element={
            <QueryClientProvider client={queryClient}>
              <routes.aboutUsUpdatePage.component />
            </QueryClientProvider>
          }
          key={routes.aboutUsUpdatePage.key}
        />
        {/* NEWS pages */}
        <Route
          path={routes.newsPage.route}
          element={<routes.newsPage.component />}
          key={routes.newsPage.key}
        />
        <Route
          path={routes.newsUpdatePage.route}
          element={
            <QueryClientProvider client={queryClient}>
              <routes.newsUpdatePage.component />
            </QueryClientProvider>
          }
          key={routes.newsUpdatePage.key}
        />
        {/* feature pages */}
        <Route
          path={routes.featuredPage.route}
          element={<routes.featuredPage.component />}
          key={routes.featuredPage.key}
        />
        <Route
          path={routes.featuredUpdatePage.route}
          element={
            <QueryClientProvider client={queryClient}>
              <routes.featuredUpdatePage.component />
            </QueryClientProvider>
          }
          key={routes.featuredUpdatePage.key}
        />
        {/* Australian Made pages */}
        <Route
          path={routes.australianMadePage.route}
          element={<routes.australianMadePage.component />}
          key={routes.australianMadePage.key}
        />
        <Route
          path={routes.australianMadeUpdatePage.route}
          element={
            <QueryClientProvider client={queryClient}>
              <routes.australianMadeUpdatePage.component />
            </QueryClientProvider>
          }
          key={routes.australianMadeUpdatePage.key}
        />
        {/* city pages */}
        <Route
          path={routes.cityPage.route}
          element={
            <QueryClientProvider client={queryClient}>
              <routes.cityPage.component />
            </QueryClientProvider>
          }
          key={routes.cityPage.key}
        />
        <Route
          path={routes.cityUpdatePage.route}
          element={
            <QueryClientProvider client={queryClient}>
              <routes.cityUpdatePage.component />
            </QueryClientProvider>
          }
          key={routes.cityUpdatePage.key}
        />
        {/* membership feature pages */}
        <Route
          path={routes.membershipFeaturesPage.route}
          element={<routes.membershipFeaturesPage.component />}
          key={routes.membershipFeaturesPage.key}
        />
        <Route
          path={routes.membershipFeaturesUpdatePage.route}
          element={
            <QueryClientProvider client={queryClient}>
              <routes.membershipFeaturesUpdatePage.component />
            </QueryClientProvider>
          }
          key={routes.membershipFeaturesUpdatePage.key}
        />
        <Route
          path={routes.membershipTypePage.route}
          element={<routes.membershipTypePage.component />}
          key={routes.membershipTypePage.key}
        />
        <Route
          path={routes.membershipTypeUpdatePage.route}
          element={
            <QueryClientProvider client={queryClient}>
              <routes.membershipTypeUpdatePage.component />
            </QueryClientProvider>
          }
          key={routes.membershipTypeUpdatePage.key}
        />
        <Route
          path={routes.subscriptionPage.route}
          element={
            <QueryClientProvider client={queryClient}>
              <routes.subscriptionPage.component />
            </QueryClientProvider>
          }
          key={routes.subscriptionPage.key}
        />
        <Route
          path={routes.reservationPage.route}
          element={<routes.reservationPage.component />}
          key={routes.reservationPage.key}
        />
        <Route
          path={routes.vendorTransactionsPage.route}
          element={<routes.vendorTransactionsPage.component />}
          key={routes.vendorTransactionsPage.key}
        />
        <Route
          path={routes.quotesPage.route}
          element={
            <QueryClientProvider client={queryClient}>
              <routes.quotesPage.component />
            </QueryClientProvider>
          }
          key={routes.quotesPage.key}
        />
        <Route
          path={routes.notFound.route}
          element={<routes.notFound.component />}
        />
      </Switch>
    </Suspense>
  );
};

export default Routes;
