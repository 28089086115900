import styled from 'styled-components'
import tw from 'twin.macro';
import Layout from 'antd/es/layout';

export const StyledHolder = styled.div`
  ${tw`p-4 flex-1`}
  margin-right: 18px;
`;
export const StyledMain = styled(Layout)`
  ${tw`min-h-screen`}
  background: linear-gradient(0.15deg, #FFFFFF 1.98%, #EAF0FF 96.7%);
  box-shadow: 99px 30px 200px -40px rgba(0, 0, 0, 0.1);
`;

