import * as React from "react";
import {
  UserType,
  LoginRequest,
  LoginResponse,
  USER_TOKEN_LOCALSTORAGE,
} from "@/api/types/user";
import { PostRequest, GetRequest } from "@/api/handleReactQuery";
import { urls } from "@/api/urls";
import { notification } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";

export type UserContextType = {
  currentUser?: UserType | null;
  setCurrentUser: (user: UserType) => void;
  checkLogin: () => void;
  choosePlatform: (platform: string) => void;
  handleLogin: (loginFormData: LoginRequest) => void;
  handleLogout: () => void;
  authenticationLoading: boolean;
  LoginLoading: boolean;
  platform: string;
};

export type ProviderProps = {
  children: React.ReactNode;
};

// @ts-ignore
export const UserContext = React.createContext<UserContextType>();
export const CurrentUserProvider = (props: ProviderProps) => {
  const [platform, setPlatform] = useState("");
  const [currentUser, setCurrentUser] = React.useState<UserType | null>(null);
  const history = useNavigate();
  const location = useLocation();
  const { mutate: mutateLogin, isLoading: LoginLoading } = PostRequest(
    urls.auth.login,
    {
      onSuccess: (tokenData: LoginResponse) => {
        localStorage.setItem(
          USER_TOKEN_LOCALSTORAGE,
          JSON.stringify(tokenData)
        );
        checkLogin();
      },
      onError: (error: any) => {
        console.error("login error", error);
        if (error?.response?.data)
          notification.error({
            message: "Login Error",
            description: "User or Password doesn't match",
          });
      },
    }
  );

  const { isLoading: authenticationLoading, refetch: mutateGetCurrentUser } =
    GetRequest(["GET_CURRENT_USER"], urls.user.me, {
      onSuccess: (data: any) => {
        setCurrentUser(data.user);
        if (location.pathname === "/login") {
          history("/", { replace: true });
        }
      },
      onError: (error: any) => {
        console.error("check login error", error);
        handleLogout();
        if (location.pathname !== "/login") {
          history("/login", { replace: true });
        }
      },
    });

  const checkLogin = () => {
    if (
      !localStorage.getItem(USER_TOKEN_LOCALSTORAGE) &&
      location.pathname !== "/login"
    ) {
      history("/choosePlatform", { replace: true });
    } else if (localStorage.getItem(USER_TOKEN_LOCALSTORAGE)) {
      mutateGetCurrentUser();
    }
  };

  const choosePlatform = (platform: string) => {
    if (location.pathname === "/choosePlatform") {
      history("/login", { replace: true });
    }
    setPlatform(platform);
  };

  const handleLogin = (loginFormData: LoginRequest) => {
    mutateLogin(loginFormData);
  };

  const handleLogout = () => {
    localStorage.clear();
    setCurrentUser(null);
    history("/choosePlatform", { replace: true });
  };

  React.useEffect(() => {
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stateValues = {
    currentUser,
    setCurrentUser,
    authenticationLoading,
    LoginLoading,
    checkLogin,
    choosePlatform,
    handleLogin,
    handleLogout,
    platform,
  };

  return (
    <UserContext.Provider key={currentUser?._id} value={stateValues}>
      {props.children}
    </UserContext.Provider>
  );
};
